@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/OpenSans-Light.woff') format('woff'),
        url('../fonts/OpenSans-Light.ttf') format('truetype'),
        url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBold.woff') format('woff'),
        url('../fonts/OpenSans-SemiBold.ttf') format('truetype'),
        url('../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff'),
        url('../fonts/OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-LightItalic.woff') format('woff'),
        url('../fonts/OpenSans-LightItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../fonts/OpenSans-Italic.woff') format('woff'),
        url('../fonts/OpenSans-Italic.ttf') format('truetype'),
        url('../fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-BoldItalic.woff') format('woff'),
        url('../fonts/OpenSans-BoldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff'),
        url('../fonts/OpenSans-Regular.ttf') format('truetype'),
        url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBold.woff') format('woff'),
        url('../fonts/OpenSans-ExtraBold.ttf') format('truetype'),
        url('../fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/Roboto-LightItalic.woff') format('woff'),
        url('../fonts/Roboto-LightItalic.ttf') format('truetype'),
        url('../fonts/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/Roboto-Italic.woff') format('woff'),
        url('../fonts/Roboto-Italic.ttf') format('truetype'),
        url('../fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
        url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BlackItalic.woff') format('woff'),
        url('../fonts/Roboto-BlackItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype'),
        url('../fonts/Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BoldItalic.woff') format('woff'),
        url('../fonts/Roboto-BoldItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/Roboto-ThinItalic.woff') format('woff'),
        url('../fonts/Roboto-ThinItalic.ttf') format('truetype'),
        url('../fonts/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/Roboto-MediumItalic.ttf') format('truetype'),
        url('../fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/Roboto-Thin.woff') format('woff'),
        url('../fonts/Roboto-Thin.ttf') format('truetype'),
        url('../fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraLight.woff2') format('woff2'),
        url('../fonts/Muli-ExtraLight.woff') format('woff'),
        url('../fonts/Muli-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Bold.woff2') format('woff2'),
        url('../fonts/Muli-Bold.woff') format('woff'),
        url('../fonts/Muli-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Muli-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Muli-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-BoldItalic.woff2') format('woff2'),
        url('../fonts/Muli-BoldItalic.woff') format('woff'),
        url('../fonts/Muli-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli.woff2') format('woff2'),
        url('../fonts/Muli.woff') format('woff'),
        url('../fonts/Muli.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Italic.woff2') format('woff2'),
        url('../fonts/Muli-Italic.woff') format('woff'),
        url('../fonts/Muli-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-SemiBold.woff2') format('woff2'),
        url('../fonts/Muli-SemiBold.woff') format('woff'),
        url('../fonts/Muli-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-LightItalic.woff2') format('woff2'),
        url('../fonts/Muli-LightItalic.woff') format('woff'),
        url('../fonts/Muli-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Light.woff2') format('woff2'),
        url('../fonts/Muli-Light.woff') format('woff'),
        url('../fonts/Muli-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Semi-BoldItalic.woff2') format('woff2'),
        url('../fonts/Muli-Semi-BoldItalic.woff') format('woff'),
        url('../fonts/Muli-Semi-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}



$openSans: 'Open Sans';
$roboto:'Roboto';
$muli: 'Muli';