@media (min-width: 2560px) {
    .investSliderTwo .savingSteps.step4 {
        right: -2.8%;
    }
    .investSliderTwo.managedSlider .savingSteps.step2 {
        right: 2%;
    }
    .investSliderTwo.managedSlider .savingSteps.step3 {
        left: -1%;
    }
    .investSliderTwo.managedSlider .savingSteps.step4 {
        right: -0.6%;
    }
}

@media (min-width: 2160px) and (max-width: 2559.98px) {
    .investmentPageOne .savingSteps.step2 {
        right: 1.5%;
    }
    .investSliderTwo .savingSteps.step2 {
        position: relative;
        right: 0.8%;
    }
    .investSliderTwo .savingSteps.step3 {
        position: relative;
        left: 1%;
    }
    .investSliderTwo .savingSteps.step4 {
        right: -1.5%;
    }
    .investSliderTwo .savingSteps.step4 {
        right: -2.8%;
    }
    .investSliderTwo.managedSlider .savingSteps.step2 {
        right: 3%;
    }
    .investSliderTwo.managedSlider .savingSteps.step3 {
        left: -1.7%;
    }
    .investSliderTwo.managedSlider .savingSteps.step4 {
        right: -0.8%;
    }
}

@media (min-width: 1920px) and (max-width: 2159.98px) {
    .investmentPageOne .savingSteps.step2 {
        right: 1.6%;
    }
    .investmentPageOne .savingSteps.step3 {
        right: 1%;
    }
    .investSliderTwo .savingSteps.step3 {
        position: relative;
        left: 1%;
    }
    .investSliderTwo .savingSteps.step4 {
        right: -1.8%;
    }
    .investSliderTwo.investSliderTwo .savingSteps.step2 {
        position: relative;
        right: 0.8%;
    }
    .investSliderTwo .savingSteps.step4 {
        right: -1.8%;
    }
    .investSliderTwo.managedSlider .savingSteps.step2 {
        right: 2.2%;
    }
    .investSliderTwo.managedSlider .savingSteps.step3 {
        left: -1.2%;
    }
    .investSliderTwo.managedSlider .savingSteps.step4 {
        right: -0.8%;
    }
}

@media (min-width: 1600px) and (max-width: 1919.98px) {
    .investSliderTwo .savingSteps.step4 {
        right: -2.8%;
    }
    .investSliderTwo.managedSlider .savingSteps.step2 {
        right: 3%;
    }
    .investSliderTwo.managedSlider .savingSteps.step3 {
        left: -1.7%;
    }
    .investSliderTwo.managedSlider .savingSteps.step4 {
        right: -1.8%;
    }
}

@media (min-width: 1440px) and (max-width: 1599.98px) {
    .investSliderTwo.managedSlider .savingSteps.step2 {
        right: 4%;
    }
    .investSliderTwo.managedSlider .savingSteps.step3 {
        left: -1.7%;
    }
    .investSliderTwo.managedSlider .savingSteps.step4 {
        right: -2.2%;
    }
}

@media (min-width: 1600px) {
    .investNow p {
        font-size: 0.875rem;
    }
}

@media (min-width: 1440px) {
    .ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader {
        max-width: 40%;
        width: 40%;
    }
    .ComapnyCardWrappersMain .CompanyListViewItm .DataSetings {
        width: 60%;
    }
    .ComapnyCardWrappersMain .CompanyListViewItm .DataSetings .dataItm {
        justify-content: center;
    }
    .ComapnyCardWrappersMain .CompanyListViewItm .DataSetings .dataItm {
        padding: 0 1rem;
    }
}

@media (max-width: 1440px) {
    .hsaFundBtn h3 {
        font-size: 1.35rem;
    }
}

@media (max-width: 1439px) {
    .investNow h2 {
        margin-bottom: 0.6rem;
        font-size: 1.425rem;
    }
    .investNow h3 {
        font-size: 1.3rem;
    }
    .investNowBtn {
        font-size: 1.15rem;
    }
    .mainMenu li a {
        padding: 1rem 1.25rem 1rem 1.875rem;
    }
    .participantChartDashboard .card-footer,
    .eligibleExpenseChart .card-footer {
        font-size: 0.75rem;
    }
    .participantChartDashboard .colorBox,
    .eligibleExpenseChart .colorBox {
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 1px;
    }
    .hsaFundBtn h3 {
        font-size: 1.2rem;
    }
    .ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader {
        max-width: 37%;
    }
    .ComapnyCardWrappersMain .CompanyListViewItm .DataSetings {
        width: 63%;
    }
    .ComapnyCardWrappersMain .CompanyListViewItm .DataSetings .dataItm {
        width: 20%;
        justify-content: center;
    }
    .ComapnyCardWrappersMain .CompanyListViewItm .DataSetings .dataItm {
        padding: 0 0.5rem;
    }
    .CompanyListViewItm .DataSetings .settingButtons.dataItm a:last-child {
        margin-left: 0.5rem;
    }
    .investMentPageSteps .ant-card-body {
        // padding-left: 38px;
        // padding-right: 38px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.fw-5 {
    font-weight: 600 !important;
    font-size: 12.85px !important;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-2 {
    margin-top: 10px;
}

.text-left {
    text-align: left;
}

@media (max-height: 630px) {
    .mainMenu li a {
        padding: 0.8rem 1.25rem 0.8rem 1.875rem;
    }
}

@media (max-width: 1800px) {
    .reportDescription {
        max-width: 650px;
    }
}

@media (max-width: 1500px) {
    .reportDescription {
        max-width: 550px;
    }
    .participantChartDashboard .chartValueText {
        padding: 0 !important;
    }
    .participantChartDashboard .colorBox {
        margin-right: 0.1rem;
    }
    .portfolioTabs .ant-tabs-tab {
        padding: 11px 12px !important;
    }
    .dotsPurple {
        margin-right: 0.2rem;
    }
    .scrollDashboard.investmentPage .ant-row {
        margin-bottom: 0px;
    }
    .investmentPage .FlipCards h3 {margin-bottom: 0;}
    .left-img-section .hsa-img {
      width: 600px;
      left: -730px;
      top: 20px;
  }
  .left-img-section .rect-img {
    left: -695px;
    top: 24px;
    width: 400px;
  }
}

@media (max-width: 1435px) {
    .chartValue {
        white-space: nowrap;
    }
    .colorBox {
        margin-right: 3px;
    }
    .chartValueText {
        padding: 0;
    }
    .ant-card-body {
        padding: 16px;
    }
    .messageCenter {
        height: 100%;
    }
    .footTextFlex {
        font-size: 12px;
        white-space: nowrap;
    }
    .card-title {
        font-size: 17px;
    }
    .profilePageTab .scrollViewMain {
        max-height: calc(100vh - 130px);
    }
    .transactionTable {
        max-height: calc(100vh - -63px);
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }
    .transactionTable.ContributionState {
        height: auto;
    }
    .contributionChart {
        height: 100%;
    }
    .DocumentPage {
        max-height: calc(100vh - 140px);
        overflow: hidden;
        overflow-y: auto;
    }
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
        padding: 10px;
    }
    .ant-table-tbody>tr>td {
        font-size: 14px;
    }
    .alertData .alertDataRow {
        line-height: 38px;
    }
    .participantChartDonut .donutChart {
        width: 50%;
    }
    .rightPartChartDetails {
        width: 50%;
    }
    .checkboxText {
        margin-left: 10px;
    }
    .profileLeftCont {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .SwitchDefault {
        margin: 0px 0 0;
    }
    .participantChartDashboard .donutChart {
        width: 100%;
    }
    .donutCenter h2 {
        font-size: 26px;
    }
    .donutCenter p {
        font-size: 13px;
    }
    .sponsorTopCards h2 {
        font-size: 32px;
    }
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
        padding: 8px 5px;
        font-size: 12px;
    }
    .viewTransaction .checkboxText:first-child {
        margin-left: 0;
    }
    .inputRows lable,
    .editSettingDisable .inputRows .ant-input,
    .editSettingDisable .inputRows .ant-select {
        font-size: 14px;
    }
    .sponsorTopCards .sponsorBottomTop {
        font-size: 12px;
    }
    .pendingParticipant h5 {
        margin-right: 10px;
        font-size: 16px;
    }
    .btnGrey,
    button.btnGrey {
        padding: 4px 10px;
    }
    .reportDescription {
        max-width: 440px;
    }
    .manageFirstSlider .savingSteps {
        font-size: 1rem;
    }
}

@media (max-width: 1400px) {
    .portfolioTabs .ant-tabs-tab {
        padding: 10px 10px !important;
    }
    .portfolioTabs .ant-tabs-tab-btn {
        font-size: 14px;
    }
    .investMentPageSteps .ant-card-body {
        // padding-left: 38px;
        // padding-right: 38px;
        padding-left: 15px;
        padding-right: 15px;
    }
  .manageFirstSlider .savingSteps{width: 120px; font-size: 15px; margin-right: 30px;}
  .manageCardsSliderButton {
    display: block;
  }
  .manageCardsSliderButton .cardSlider {
    margin: 0 auto 40px;
  }
  .manageCardsSliderButton .buttonTabs {
    width: 100%;
    text-align: center;
    min-height: 400px;
  }



}

@media (max-width: 1365.98px) {
    .managedTabs.portfolioAllocationTab .portChartDonutTotalShow {
        z-index: 9;
    }
    .managedTabs.portfolioAllocationTab .portChartDonutTotalShow h2 {
        font-size: 1rem;
    }
    .left-img-section .hsa-img {
      width: 500px;
      left: -600px;
      top: 20px;
    }
  .left-img-section .rect-img {
    left: -555px;
    top: 31px;
    width: 300px;
  }

}

@media (max-width: 1300px) {
    .footTextFlex {
        font-size: 11px;
    }
    .chartValue {
        margin-bottom: 11px;
        font-size: 0.687rem;
    }
    .participantChartDashboard .colorBox {
        margin-right: 0;
    }
    .investmentPage .investSteps {background-size: 60px; width: 60px;
      height: 60px;}
    .investmentPage .investmentSteps {padding: 27px 30px 80px;}
    .investmentPage .stepRightText {font-size: 16px;}
    .investmentPage .investCenterText {font-size: 18px;}
    .investmentPage .savingSteps {height: 40px; padding-top: 40px; font-size: 14px;}
    .investmentPage .FlipCards {width: 260px; min-height: 400px;}
    .investmentPageThree .investmentBody{padding: 0;}
    .investmentPageThree .react-card-flip {margin: 0 10px; max-width: 290px; min-height: 390px;}
    .investmentPageThree .chooseInvest h2 {font-size: 20px;}
    .investmentPage .investSubHead {font-size: 22px;}
    .investmentPage .FlipCards h3 {font-size: 22px;}
    .investmentPage .p-card-bottom {left: 13%;}
    .investmentPage .manageCardsSliderButton .cardSlider .ant-carousel {  min-height: 290px;  }
    .investmentPage .manageCardsSliderButton .cardSlider {width: 600px;height: 375px;}
    .investmentPage .manageCardsSliderButton .buttonTabs {min-height: 350px;}
    .investmentPage .manageCardsSliderButton .radioListManage span{font-size: 14px;}
    .investmentPage .manageCardsSliderButton .cardSlider p {font-size: 14px;}
    .manageTextsCon h2 {font-size: 22px;}
    .investmentPage .ComapnyCardWrappersMain .CompanyCardViewItm {width: calc(25% - 0.8rem) !important;}
    .investmentPage .ComapnyCardWrappersMain{justify-content: center;}
    .investmoreModal .priceHSAGreen {font-size: 22px;}
    .investmentPage .totalcashchart {  min-height: 150px !important;}
    .investmentPage .totalcashchart .apexcharts-canvas {width: 194px !important; height: 149.7px !important;}
    .investmentPage .portChartDonutTotalShow .chart-text{font-size: 14px;}
    .investmentPage .portDonutTotalValue {font-size: 13px;}
    .sponsorTopCards h2 {font-size: 26px;}
    .sponsorTopCards .card-title{font-size: 15px;}
    .sponsorTopCards .viewTransaction{font-size: 12px;}
    .sponsorTopCards .ant-card-body {padding: 15px;}
    .ReportPageScroll .formatSelect {min-width: 100px; width: auto;}
    .ReportPageScroll .buttonMain.downloadBtnPdf {min-width: 100px; width: auto;}

}
@media (max-width: 1200px) {
  .participantsMainDashboard .netBlanceBtn h2 {font-size: 24px;}
  .participantsMainDashboard .middleContent p {font-size: 17px;}
  .investmentPage .portfolioTabs .ant-tabs-tab-btn {  font-size: 13px;}
  .investmentPage .portfolioTabs.managedTabs .ant-tabs-nav-list .ant-tabs-tab:nth-child(1) { margin-right: 0 !important;}
  .investmentPage .portfolioTabs.managedTabs .ant-tabs-nav-list {justify-content: space-evenly;}
  .investmentPage .InvestMentCardInner h3 {font-size: 16px;}
  .investmentPage .counterTitle h2 {font-size: 18px;}
  .investmentPage .investedPortfolio .heightAuotoCard{margin-top: 20px;}
  .investmentPage .investedPortfolio .heightAuotoCard h2{font-size: 20px;}
  .ExpensesPage h2 {font-size: 20px;}
  .ExpensesPage .add-expense-table label {font-size: 14px;}
  .ExpensesPage .upload-text{font-size: 18px !important;}
  .investmentBotomTable {  margin-top: 0px;}
  .DocumentTable .ant-table-tbody > tr > td {
  font-size: 12px;}


}
@media (max-width: 1100px) {
  .investmentPage .FlipCards {
    width: 235px;
    min-height: 420px;
  }
  .investmentPage .p-card-bottom {left: 9%;}
  .investmentPage .ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader .brandinfo .brandLogo {width: 40px;}
  .investmentPage .ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader .brandinfo .brandName h4 {font-size: 16px;}
  .investmentPage .ComapnyCardWrappersMain .CompanyListViewItm .companyCardHeader .brandinfo .brandName h5 {font-size: 13px;}
  .investmentPage .ComapnyCardWrappersMain .CompanyListViewItm .DataSetings .dataItm h6{font-size: 13px !important;}
  .investmentPage .portfolioTabs.managedTabs .ant-tabs-nav-list .ant-tabs-tab:nth-child(1) {margin-right: 0 !important; margin-left: 0!important;}
  .investmentPage .portfolioTabs.managedTabs .ant-tabs-nav-list {justify-content: space-around;}
  .investmentPage .out-Grey-btn {font-size: 12px; padding: 0.3rem 10px;}
  .investmentPage .investMentPageSteps .ButtonFilterHeader .ant-col{flex: 0 0 100%; max-width: 100%;}
  .investmentPage .investMentPageSteps .ButtonFilterHeader .rangeFilter.ml-auto {margin-left: 0; margin-top: 20px;}
  .investmentPage .TablePortfolio {margin: 0 0 30px;}
  .participantsMainDashboard.scrollDashboard .ant-row {
    margin-bottom: 0px;
  }
  .participantsMainDashboard .cardFooterTwo, .contributionpage .cardFooterTwo {
    width: 100%;
    float: none;
    display: flex;
    justify-content: center;
  }
  .participantsMainDashboard .cardFooterTwo table, .contributionpage .cardFooterTwo table{
    width: 70%;
  }
  .contributionpage.scrollDashboard .ant-row {
  margin-bottom: 0px;
  }
  .contributionpage .marginBtMonth {
    margin-bottom: 0 !important;
  }
  .contributionpage .filterContribution {margin-bottom: 0;}
  .Expenses-screen .ant-tabs-content .mb-3 {  margin-bottom:0 !important;}
  .forYouPosts h2 {font-size:18px;}
  .blogBanner {height: 200px;}
  .ForYouRightSidebar h2, .ForYouRightSidebar h3{font-size: 18px;}
  .sidebarHeaderClose {font-size: 20px;}
  .transactionTable .ant-table-container {width: 800px;overflow-x: scroll;overflow-y: auto;}
  .col-responsive {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .blogBanner {height: auto;}
  .forYouPosts {margin: 0;}
  .sidebarClose {right: 4px;}
  .ForYouSideRightSidebarFooter {text-align: center;}
  .reportDescription {max-width: fit-content; width: fit-content;}
  .ReportPageScroll .reportsTable .ant-table-container {width: 800px;overflow-x: scroll !important;overflow-y: auto !important;}
  .ReportPageScroll .settingAdminScroll .ant-table-body {overflow: scroll !important;}
  .ReportPageScroll .reportsTable .ant-card-body {
  overflow-y: auto;
  }
  .ReportPageScroll .buttonMain.downloadBtnPdf {
    min-width: auto;
    width: auto;
    padding: 0px 10px;
    font-size: 11px;
  }
  .ReportPageScroll .formatSelect {
    min-width: auto;
    width: auto;
  }
  .leftSelectBoxReport .DefaultSelect.ant-select:not(.ant-select-customize-input) .ant-select-selector {font-size: 12px;}
  .sponsorTopCards h2 {font-size: 24px;}
  .ant-modal-title {font-size: 22px;}
  .defaultForm .ant-form-item-label > label {font-size: 17px;}
  .defaultForm .ant-btn-primary, .defaultForm .ant-btn {font-size: 16px;}
  .EditSettingForm .profileRightMenu {
  position: fixed;
  top: 96px;
  display: flex;
  width: 76%;
  background: #eff0f2;
}
.settingPageScroll.scrollTabs {margin-top: 50px;}
.left-img-section .hsa-img {
  width: 400px;
  left: -530px;
  top: 25px;
}
.left-img-section .rect-img {
  left: -500px;
  top: 31px;
  width: 250px;
}

}
@media (max-width: 1024px) {
    .ant-modal {
        min-width: 576px;
    }
    .investmentPage .card-header.noBorder{display: none;}
    .investmentPage .investSteps {
      background-size: 50px;
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    .investmentPage .investmentSteps {padding: 27px 20px 60px;}
    .investmentPage .investCenterText{margin-top: 0;}
    .investmentPage .investSlider{width: 100%;}
    .investmentPage .investSubHead {font-size: 20px;}
    .manageTextsCon h2 {font-size: 22px;}
    .investmentPage .align-card {display: block;}
    .investmentPageThree .react-card-flip {
        margin: 0 10px;
        max-width: 100%;
        min-height: 100%;
      }
      .investmentPage .FlipCards {
      width: 70%;
      min-height: 320px;
      height: 320px;
    }
    .investmentPage  .FlipCards.FrontCard p {
      min-height: 60px;
    }
    .investmentPage .p-card-bottom {
      left: 33%;
    }
    .investmentPage .manageCardsSliderButton .radioListManage span{font-size: 13px;}
    .investmentPage .manageCardsSliderButton .cardSlider p {font-size: 13px;}
    .investmentPage .savingSteps {font-size: 13px;}
    .investmentPage .stepRightText {font-size: 14px;}
    .investmentPage .ComapnyCardWrappersMain .CompanyCardViewItm {width: calc(30% - 0.8rem) !important;}
    .investmentPage .ComapnyCardWrappersMain.listview .CompanyListViewItm{flex: 0 0 auto;width: 850px;}
    .investmentPage .ComapnyCardWrappersMain.listview{max-width: 100%;overflow: scroll;width: 100%;flex-direction: column; flex-wrap: unset;}
    .investNow h3 {font-size: 18px;}
    .investNow h2 {font-size: 20px;}
    .participantsMainDashboard .middleContent p {font-size: 16px;}
    .participantsMainDashboard .netBlanceBtn h2 {font-size: 22px;}
    .monthBox h2 {font-size: 24px;}
    .participantsMainDashboard .investNow{margin-top: 25px;}
    .participantsMainDashboard .flexMiddle.monthBox {height: auto;}
    .investmentPage .InvestmentsCard .ant-col-6 {flex: 0 0 48%;max-width: 48%; margin-bottom: 20px;}
    .investmentPage .investmorebtn{top: -188px;}
    .investmentPage .InvestmentsCard .InvestMentCardInner h3 {min-height: 40px;}
    .hsaFundBtn h3 {font-size: 20px;}
    .moveMoneyWrapper .MoveMoneyBtn .hsaFundBtn {margin:0; max-width: 400px;}
    .participantsMainDashboard .investColorCardMain{display: block; width: 100%;}
    .participantsMainDashboard .investColorCardMain .investColoCards {  width: 100%; margin-bottom: 20px; margin-left: 0;}
    .investmentTabs .ant-tabs-nav .ant-tabs-nav-wrap{width: 100%; padding: 0}
    .investmentTabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list{width: 100%; justify-content: space-evenly;}
    .investmentTabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {margin: 0 0 0 20px;font-size: 16px;}
    .ExpensesPage .uploadLargeIcon{width: 70px; height: 60px;}
    .ExpensesPage .uploadDocuments {padding: 30px 0; overflow: auto;}
    .Expenses-screen .expenseTable table th{font-size: 11px !important;}
    .Expenses-screen .expenseTable .expandable-padding tr td{display: initial !important;}
    .Expenses-screen .expenseTable .expandable-padding tr{text-align: center;}
    .filteration-table-form .ant-form-item-label > label {font-size: 14px !important;}
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {font-size: 12px;}
    .inputIcons.Dollar.dollerBg {background-size: 8px;}
    .ant-picker-input > input {font-size: 10px; padding: 5px;}
    .pills {font-size: 12px;}
    .profileTopBar .ant-tabs-tab { padding: 12px 16px 5px;}
    .expImagePreview {top: 20px;}
    .closeExpenseImg {top: 10px; right: 10px;}
    .expenseTable .ant-table-expand-icon-col {  width: 30px;}
    .expenseTable .ant-table-container{width: 800px;}
    .Expenses-screen .ant-tabs-content {overflow-y: auto;overflow-x: hidden;}
    .expenseTable.tableoverview.ant-table-wrapper {overflow-y: auto;  max-height: calc(100vh - 165px);}
    .autoCompleteSelectCont .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {padding-left: 0;}
    .autoCompleteSelectCont  .ant-select:not(.ant-select-customize-input) .ant-select-selector {font-size: 10px;}
    .autoCompleteSelectCont .selectBoxTopBar .ant-select-single.ant-select-show-arrow .ant-select-selection-item {padding-right: 30px;}
    .ant-select-item-option-content {font-size: 12px; text-align: center; padding: 0 5px;}
    .ant-select-item {padding: 5px 5px;}
    .DocumentsSponsorPage.scrollDashboard .ant-row {
      margin-bottom: 0;
    }

}

@media (max-width: 1190px) {
    .leftSidebar {
        width: 150px;
    }
    .mainContent {
        width: calc(100% - 150px);
    }
    .containerNew {
        padding: 15px;
    }
    .mainMenu li a {
        padding: 18px 6px 18px 6px;
    }
    .iconLetBar {
        background-size: 20px;
        width: 20px;
        height: 20px;
        margin-right: 2px;
        font-size: 11px;
    }
    .countLeftSide {
        right: 8px;
    }
    .profileIcon {
        width: 30px;
        height: 30px;
    }
    .leftName {
        font-size: 11px;
    }
    .card-title {
        font-size: 15px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 5px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding-left: 6px;
    }
    .donutCenter h2 {
        font-size: 20px;
    }
    .donutCenter h2 span {
        font-size: 10px;
    }
    .donutCenter p {
        font-size: 9px;
    }
    .footTextFlex {
        font-size: 9px;
    }
    .greyBtnDefault {
        font-size: 12px;
    }
    .chartValue {
        white-space: pre-wrap;
    }
    .ant-select.defaultSelect {
        padding: 2px;
    }
    .monthBox h2 {
        font-size: 28px;
    }
    .mainMenu li.logo div {
      padding: 16px 10px;
    }
    .leftSidebar li.logo img {
      max-width: 100%;
      width: 100px;
      min-width: 100px;
    }
    .menuIconCollapse {width: 30px;height: 30px;background-size: 30px;}
    .leftSidebar:not(.Collapsed) .mainMenu li a.menuHideShow {
      right: -15px;
    }

}

// telephone input
.EditSettingForm .inputRows .react-tel-input {
    max-width: 315px;
    margin-left: auto;
}

//  responsive by jeetu
body {
    background-color: #f9f9f9;
}

.mt-0 {
    margin-top: 0;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.chartLabelText {
    text-align: center;
    width: 70%;
}

.chart-font {
    color: #686c77;
    font-weight: 600;
}

.chart-font.active {
    color: #35b289;
    font-weight: 600;
}

.portfolioTabs.managedTabs {
    .ant-tabs-nav-list {
        display: -webkit-flex;
        display: flex;
        list-style-type: none;
        padding: 0;
        justify-content: flex-end;
        width: 100%;
        margin: 0 14px;
    }
    .ant-tabs-nav-list .ant-tabs-tab:nth-child(1) {
        margin-right: auto;
        margin-left: 0 !important;
    }
    .portfolioTabs.managedTabs .ant-tabs-nav-list .ant-tabs-tab:nth-child(1) {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

.br-0 {
    border-radius: 0;
    border-left: 1px solid #686c77;
}

.position-relative {
    position: relative;
}

.auth-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// for scroll within card
.FlipCards.BackCard {
    .ant-collapse {
        max-height: 295px;
        overflow-y: auto;
        margin-bottom: 7px;
    }
}

.align-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.flipCard-bg-1 .ant-collapse>.ant-collapse-item {
    border-left: 4px solid #11a79a !important;
}

.ant-collapse-header {
    color: #11a79a !important;
}

.flipCard-bg-2 .ant-collapse>.ant-collapse-item {
    border-left: 4px solid #0d78c3 !important;
    .ant-collapse-header {
        color: #0d78c3 !important;
    }
}

.flipCard-bg-3 .ant-collapse>.ant-collapse-item {
    border-left: 4px solid #646464 !important;
    .ant-collapse-header {
        color: #11a79a;
    }
}

.ant-collapse::-webkit-scrollbar {
    width: 5px;
}

.ant-collapse::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.ant-collapse::-webkit-scrollbar-thumb {
    background: #48706473;
    border-radius: 10px;
}

.radioListManage label {
    display: flex; // for radio button and text
}

.stepTabsFive {
    .portfolioTabs .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
        justify-content: flex-start;
    }
    .ant-tabs-nav-list .ant-tabs-tab:nth-child(1) {
        margin-right: 4rem;
        min-width: 120px;
        justify-content: center;
    }
}
@media (max-width: 1000px)
{
  .investmentPage .portfolioTabs .portfolioCharts .currentLeft{width: 100%; float: none;}
  .investmentPage .portfolioTabs .portfolioCharts {display: block;}
  .investmentPage .portfolioTabs .portfolioCharts .currentLeft.br-0 {border: 0;}
  .investmentPage .portfolioTabs .portfolioCharts .currentLeft .chartLabelText {width: 100%;}
  .investmentPage .portfolioTabs.managedTabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{white-space: inherit;}
  .investmentPage .portfolioAllocationTab .recommendedTextTopPort {left: 30px;}
}
@media (max-width: 991px)
{
  .login-page .auth-container {
  text-align: center;
  }
  .login-page .login-confirm {margin: 0 auto;}
}

@media (max-width: 900px)
{
  .manageFirstSlider .savingSteps {
    width: 100px;
    font-size: 13px;
    margin-right: 10px;
  }
  .investmentPage .stepRightText {
    font-size: 14px;
    line-height: 18px;
  }
  .investmentPage .investCenterText {
    font-size: 16px;
  }
  .investmentPage p {font-size: 13px; line-height: 20px;}
  .investmentPage .savingSteps {font-size: 12px;}
  .investmentPage .investSubHead {
    font-size: 17px;
  }
  .investmentPage .investBanner {max-width: 200px;}
  .investmentPageOne .ant-col-md-12:first-child, .investPageStepTwo .ant-col-md-12:first-child{flex: 0 0 60%;
    max-width: 60%;}
    .investmentPageOne .ant-col-md-12:nth-child(2), .investPageStepTwo .ant-col-md-12:nth-child(2) {flex: 0 0 40%;
    max-width: 40%;}
    .investmentPage .FlipCards h3 {
      font-size: 19px;
    }
    .investmentPage .manageCardsSliderButton .cardSlider {width: 100%;height: 330px;}
    .investmentPage  .manageCardsSliderButton .buttonTabs {min-height: 360px;}
    .investmentPage .manageCardsSliderButton .cardSlider .ant-carousel {  min-height: 240px;}
    .investmentPage .manageSliderCont {margin: 30px 0 20px;}
    .investmentPage .radioListManage label {text-align: left;}
    .investmoreModal .hsaFundBtn{margin: 0;}
    .investmoreModal .hsaFundBtn.fundingAmountRightDiv{margin-right:20px;}
    .investmoreModal{width: 700px !important;}
    .investmoreModa .hsaButtonBorder {padding: 10px;}
    .investmoreModal .priceHSAGreen {font-size: 20px;}
    .investmentPage .portfolioAllocationTab .recommendedTextTopPort {left: 0px; font-size: 12px;}
    .portfolioTabs .ant-tabs-tab {padding: 5px 5px !important;}
    .hsaFundBtn h3 {font-size: 16px;}
    .moveMoneyWrapper .MoveMoneyBtn .fundingAmountRightDiv span{font-size: 13px;}
    .MoveMoneyBtn .HsaFundBalance {margin-bottom: 30px;}
    .MoveMoneyBtn .priceHSAGreen {font-size: 18px;}
    .moveMoneyWrapper .MoveMoneyBtn .fundingAmountRightDiv .priceHSAGreen input {font-size: 18px;}
    .MoveMoneyBtn .onlyBorder .iconShopHSA, .MoveMoneyBtn .fundingAmountRightDiv .iconShopHSA {background-size: 38px;}
    .moveMoneyWrapper .MoveMoneyBtn .iconShopHSA {width: 40px; min-width: 40px;}
    .moveMoneyWrapper .MoveMoneyBtn .priceHSAGreen {width: 150px;}
    .moveMoneyWrapper .MoveMoneyBtn .hsaFundBtn {max-width: 300px;}
    .investmentTabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {padding: 12px 10px; font-size: 14px; margin: 0 0 0 10px;}
    .ExpensesPage h2 {  font-size: 18px;}
    .investmentPage .investedPortfolio .heightAuotoCard h2 {font-size: 18px;}
}
@media (max-width: 800px)
{
  .investmentPage .ComapnyCardWrappersMain .CompanyCardViewItm {
    width: calc(50% - 0.8rem) !important;
  }
  .investmentPage .out-Grey-btn {padding: 0.3rem 5px;}
}

@media (max-width: 475px) {
    .auth-container {
        padding: 1.5rem;
        padding-top: 4rem;
    }
    .login-page .login-logo img {
        width: 235px;
    }
    .react-card-flip {
        margin: 0;
    }
    .investmentPage {
        .investBannerText {
            padding: 0;
        }
        .noBorder {
            margin: 0;
        }
        .ant-card-body {
            padding: 16px;
        }
    }
}

@media (min-width: 476px) and (max-width: 768px) {
    .auth-container {
        padding: 2.5rem;
    }
    .react-card-flip {
        margin: 0;
    }
    .investmentPage {
        .investBannerText {
            padding: 0;
        }
        .noBorder {
            margin: 0;
        }
        .ant-card-body {
            padding: 16px;
        }
    }
}

@media (min-width: 767px) {
    .auth-container {
        padding: 4rem;
    }
    .manageSliderCont {
        padding: 0 25%;
    }
}

.flipCard-bg-1 {
    background: linear-gradient(200.78deg, #23bdb8 8.53%, #43e794 91.47%), linear-gradient(0deg, #ffffff, #ffffff);
}

.recom1 {
    background: #33d2a7;
}

.recomButton1 {
    border: 1px solid #128a85 !important;
    color: #24beb8 !important;
}

.flipCard-bg-2 {
    background: linear-gradient(201.03deg, #0196ff 7.56%, #43d9f4 91.99%), linear-gradient(0deg, #ffffff, #ffffff);
}

.recom2 {
    background: #33d2a7;
}

.recomButton2 {
    border: 1px solid #0297ff !important;
    color: #0398ff !important;
}

.flipCard-bg-3 {
    background: linear-gradient(201.03deg, #747474 7.56%, #ededed 91.99%), linear-gradient(0deg, #ffffff, #ffffff);
}

.recom3 {
    background: #a8a8a8;
}

.recomButton3 {
    border: 1px solid #8f8787 !important;
    color: #c0b2b2 !important;
}

.manageCardsSliderButton {
    .ant-tabs-nav-wrap {
        padding: 0;
    }
    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
        border-radius: 5px 5px 0 0;
    }
}

.manageCardsSliderButton .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
    padding: 5px 10px;
}

.chart-text {
    font-weight: 800;
    margin-bottom: 2px;
    font-size: 16px;
}

.recomendedFlagsCont .recommendedTextTopPort {
    left: 0 !important;
}

@media (min-width: 1339px) {
    .slideDots.SlideTwo:after {
        width: 250%;
        height: 2px;
        left: -190%;
    }
}

// progressbar
.slideDots {
    z-index: 9;
}

.manageSteps1 .managedSlidesRound:after {}

.manageSteps2 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 40%;
    height: 2px;
}

.manageSteps3 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 40%;
    height: 2px;
}

.manageSteps4 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 60%;
    height: 2px;
}

.manageSteps5 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
}

.manageSteps6 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
}

// ========================================
// =============== EXPENSE  ==============
// ========================================
.m-0 {
    margin-bottom: 0 !important;
}

.pb-0 {
    padding-bottom: 0;
}

.mt-3 {
    margin-top: 2rem;
}

.ps-1 {
    padding-left: 0.23rem;
}

.first-card {
    background: linear-gradient(201.03deg, #23bdb8 7.56%, #43e794 91.99%), linear-gradient(0deg, #ffffff, #ffffff);
}

.second-card {
    background: linear-gradient(180deg, #43d9f4 0%, #0196ff 100%);
}

.third-card {
    background: linear-gradient(200.78deg, #b26df5 8.53%, #531c89 91.47%), linear-gradient(0deg, #ffffff, #ffffff);
}

.title-o-card {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 25px;
    margin-bottom: 12px;
    color: #ffffff;
    height: 38px;
}

.the-amount {
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
}

@media (max-width: 768px) {
    .the-amount {
        font-size: 25px;
    }
}

.show-hide-filter {
    color: #898e94 !important;
    background: #fff !important;
    padding: 6px 14px !important;
}

.d-inline-flex {
    display: inline-flex;
}

.pill-head {
    font-size: 16px;
    font-weight: 700;
    color: #2d3b55;
    margin-bottom: 8px;
}

.pills {
    padding: 2px 10px 4px;
    border-radius: 34px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #cecfd3;
    font-weight: 700;
    display: inline-flex;
    color: #49505a;
    align-items: center;
    cursor: pointer;
}

.green-pill {
    background: #c3f0c8;
    color: #49505a;
}

.mt-2 {
    margin-top: 0.65rem;
}

.ps-2 {
    padding-left: 6px;
}

.btnSearch .ant-input-group-addon button {
    height: 38px !important;
}

.Expenses-screen {
    .autoCompleteSelectCont .selectBoxTopBar {
        max-width: 180px;
        min-width: 150px;
    }
    .paid-table tr td {
        font-size: 16px;
        padding: 12px 0px 12px 12px;
        height: 63px;
    }
    .paid-table .ant-table-thead>tr>th {
        font-size: 14px;
        font-weight: 700;
        padding: 12px 0px 12px 12px;
    }
    .paid-table tr td:nth-child(3) {
        font-weight: 600;
    }
    .paid-table tr td:nth-child(6) {
        font-weight: 900;
        color: #49505a;
    }
    .paid-table tr td:nth-child(2),
    .paid-table tr td:nth-child(5),
    .paid-table tr td:nth-child(7) {
        font-weight: 400;
    }
    .paid-table {
        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            content: none;
        }
    }
    .ant-tabs-content {
        overflow-y: auto;
    }
}

.headingTopWithButton {
    width: 100%;
    a {
        float: right;
        &:hover button.theme-button {
            background: #37c597;
            color: #ffffff;
        }
    }
}

.theme-button {
    background: #37c597;
    color: #fff;
    font-weight: bold;
    width: 175px;
    font-size: 14px;
    height: 40px;
    border: 0;
    border-radius: 6px;
}

@media (max-width: 870px) {
    .paid-table {
        .ant-table table {
            width: 150%;
        }
        .ant-table-content {
            overflow: auto;
        }
    }
}

// form filter
.filteration-table-form {
    .ant-form-item-label>label {
        font-size: 16px;
        line-height: 24px;
        color: #2d3b55;
        font-weight: 700;
    }
    .ant-form-item-control-input-content:hover .ant-input,
    .ant-select:hover,
    .ant-picker-range:hover {
        border: 1px solid #898e94;
    }
}

.filteration-table-form .ant-form-vertical .ant-form-item-label {
    padding: 0 0 4px !important;
}

.inputIcons.Dollar {
    height: 38px;
    border-radius: 6px 0 0 6px;
}

.responsive-btn {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    padding: 19px 20px !important;
}

.inputIcons.Dollar.dollerBg {
    background-color: transparent !important;
    background-size: 10px;
}

.ant-picker.ant-picker-range {
    border: 1px solid rgba(134, 141, 149, 0.24);
    border-radius: 4px;
}

@media (min-width: 600px) and (max-width: 768px) {
    .autoCompleteSelectCont {
        width: 80%;
    }
}

.disabledField {
    .ant-select,
    .ant-input,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: #cecece !important;
    }
}

// add -expense
.add-expense-table {
    .ant-input:hover,
    .ant-input:focus {
        border: 1px solid #898e94;
        box-shadow: none;
    }
    label {
        font-weight: 400;
        font-size: 16px;
        display: block;
        margin-bottom: 4px;
    }
    .inputRows {
        margin-bottom: 17px;
    }
    .ant-picker-input input {
        border: 1px solid rgba(134, 141, 149, 0.24) !important;
        &:hover {
            border: 1px solid #898e94 !important;
        }
    }
}

.uploadDocuments:hover .expImagePreview {
    border: 1px dashed #37c597;
}

.uploadDocuments {
    input[value*="blob"]+.expImagePreview {
        background-color: #f9f9f9;
        border-radius: 6px;
    }
}

.upload-text {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    padding-top: 20px;
    color: #2d3b55 !important;
}

.browse-btn,
.browse-btn:focus,
.browse-btn:hover {
    height: 42px;
    width: 180px;
    border-radius: 4px;
    background: #37c597;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: 700;
    border: 0;
    justify-content: center;
    margin: auto;
}

.uploadDocuments {
    background: #fff;
}

.d-flex {
    display: flex;
}

.h-100 {
    height: 100%;
}

.align-items-baseline {
    align-items: baseline;
}

.mr-3 {
    margin-right: 1rem;
}

.mb-1 {
    margin-bottom: 0.5rem !important;
}

.my-1 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
}

.fw-500 {
    font-weight: 500;
}

.subtitles {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2d3b55;
    text-align: left;
}

.normal-text {
    font-size: 1rem;
    color: #919191;
}

.tnc {
    font-size: 12px;
    color: grey;
    width: 84%;
    text-align: center;
    margin: 10px auto 14px;
}

.text-decoration-underline {
    text-decoration: underline;
}

.continue-btn {
    background: black;
    color: white;
    height: 48px;
    font-size: 16px;
    &:hover {
        border: 0;
        background: #000;
        color: #ffffff;
    }
}

.banking-popup {
    .BankingHeader {
        font-weight: 500;
        text-align: center;
    }
    .ant-modal-content {
        width: 65%;
        margin: auto;
    }
}

.position-absolute {
    position: absolute;
}

.r-0 {
    right: 0;
}

// .ant-table-cell {
//   z-index: 999;
// }
.ant-table-cell-ellipsis {
    overflow: inherit;
    word-break: break-word;
    white-space: break-spaces;
    // z-index: 99999;
}

.finalQuestions {
    .ant-radio-group {
        .ant-radio-wrapper {
            margin-right: 16px;
        }
        display: inline-flex;
        // &:nth-child(4) {
        //   display: block;
        // }
    }
}

.finalQuestions .ant-radio-group:nth-child(4) {
    display: block;
}

.pending-alert {
    position: absolute;
    right: -28px;
    top: 28px;
    display: flex;
    background: #ffb6c9;
    padding: 1rem 1.6rem;
    border-radius: 6px;
    border: 2px solid #e73b3b;
    z-index: 9;
    svg {
        margin-right: 10px;
        color: #e73b3b;
        height: 20px;
        width: 20px;
    }
    .close-alert {
        position: absolute;
        right: -20px;
        top: -5px;
    }
    .msg-alert-head {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 6px;
        margin-top: 0;
        color: #1b2531;
    }
    .msg-alert-text {
        font-size: 12px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 4px;
        color: #1b2531;
    }
    .msg-alert-link {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        color: #1b2531;
        text-decoration: underline;
    }
}

.antd-progressbar {
    .ant-steps-item-icon {
        width: 22px;
        height: 22px;
        margin: 0;
        line-height: 21px;
        font-size: 12px;
        .ant-steps-icon {
            background: transparent;
        }
    }
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #35b289;
        border-color: #35b289;
    }
    .ant-steps-item-title::after {
        height: 2px;
    }
    .ant-steps-item-title::after {
        top: 12px;
        left: 0;
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        padding-left: 0;
    }
    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
        background-color: #35b289;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        background: #35b289;
    }
    .ant-steps-item-process .ant-steps-item-icon {
        border-color: #35b289;
    }
}

#plaid-link-iframe-1 .Button-module_button__1yqRw {
    background: #37c597;
}

.manageSteps1 .managedSlidesRound:after {
    background: #38c8b7;
    border-radius: 3.95823px;
    content: "";
    position: absolute;
    width: 20%;
    height: 2px;
}

.textareaBox {
    width: 100%;
    border: 1px solid #dddddd;
    padding: 10px;
    border-radius: 10px;
    resize: none;
}

@media (max-width: 1197px) {
    .FlipCards {
        width: 260px;
        p {
            margin-bottom: 0;
        }
    }
    .react-card-flip {
        margin: 0 8px;
    }
    .feeFlip {
        margin: 18px 0 28px;
    }
}

.FlipCards {
    min-height: 360px;
}

.priceHSAGreen .ant-input:focus,
.ant-input-focused {
    border-color: transparent;
    box-shadow: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
}

.priceHSAGreen {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        -webkit-text-fill-color: #37c597 !important;
    }
}

%--value {
    display: inline-flex;
    padding: 6px 12px;
    align-items: baseline;
    border-radius: 10px;
    justify-content: space-around;
}

.--value-no {
    background: #e1e1e1;
    color: #6a6a6a !important;
    @extend %--value;
}

.--value-Up {
    background: #d9f5eb;
    color: #02b177 !important;
    @extend %--value;
}

.--value-down {
    background: #ffdddc;
    color: #cd4945 !important;
    @extend %--value;
}

// ishare pane
.space-between {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

%ishare-card {
    border-radius: 12px;
    color: #fff;
    width: 296px;
    .value-change {
        background: #ffffff2e;
        border: none;
        padding: 2px 11px;
        border-radius: 16px;
        color: #fff;
        margin-left: 6px;
        font-size: 14px;
    }
}

%value-reflect {
    border: none;
    font-size: 0.985rem;
    padding: 6px 9px;
    border-radius: 7px;
    font-weight: 600;
    float: right;
    margin-top: 10px;
}

.ishare-card1 {
    background: #50b5ff;
    min-height: 112px;
    span {
        font-size: 1rem;
        font-weight: 400;
    }
    @extend %ishare-card;
    h4 {
        font-size: 28px;
        margin-top: .35rem;
        color: #fff;
    }
    .value-reflect1 {
        @extend %value-reflect;
        color: #50b5ff;
    }
}

.ishare-card2 {
    min-height: 112px;
    background: #82c43c;
    span {
        font-size: 1rem;
        font-weight: 400;
    }
    @extend %ishare-card;
    h4 {
        font-size: 28px;
        margin-top: .35rem;
        color: #fff;
    }
    .value-reflect2 {
        @extend %value-reflect;
        color: #82c43c;
    }
}

.value-change {
    background: #ffffff2e;
    border: none;
    padding: 2px 11px;
    border-radius: 16px;
    color: #fff;
    margin-left: 6px;
}

.overlay-text {
    font-weight: 500;
    font-size: 20px;
    color: #666666;
    margin: -6px 0px 1.2rem;
}

.self-directed-overview-tabs {
    background: #FBFBFB;
    border: 1px solid #38c597;
    border-radius: 7px;
    margin-top: 5rem;
    .ant-tabs-tab {
        width: 135px;
        justify-content: center;
    }
    .ant-tabs-tab-btn {
        color: #38c597;
        font-weight: 300;
    }
    .ant-tabs-tab-active {
        background-color: #38c597;
        .ant-tabs-tab-btn {
            color: #fff !important;
            font-weight: 300;
        }
    }
    .ant-tabs-top>.ant-tabs-nav::before,
    .ant-tabs-bottom>.ant-tabs-nav::before,
    .ant-tabs-top>div>.ant-tabs-nav::before,
    .ant-tabs-bottom>div>.ant-tabs-nav::before {
        border-bottom: none;
    }
    .ant-tabs-tab {
        padding: 8px 16px 12px;
    }
    .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
    }
    .ant-tabs-ink-bar {
        display: none;
    }
    .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
        justify-content: center !important;
    }
    .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
        border: 1px solid #38c597;
        margin-top: 20px;
        border-radius: 40px;
        overflow: hidden;
        background: white;
    }
}

.ant-card.self-directed-overview {
    margin: -24px;
    .ant-card-body {
        box-shadow: 1px 1px 10px #cfcfcf;
        padding: 0 20px;
    }
}

.special-menu {
    .ant-menu-vertical {
        border: 2px solid #6666ce;
        border-radius: 10px;
        box-shadow: 4px 7px 14px #00000024;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        .ant-menu-item-only-child {
            border-bottom: 2px solid #38c597;
            padding: 0 25px;
            margin-top: 2px;
            line-height: 2;
            margin-bottom: 2px;
            margin-top: 0;
            margin-bottom: 0;
            &:nth-child(4) {
                border-bottom: 0;
            }
        }
        .ant-menu-item a:hover {
            color: #000000;
        }
    }
}

.expandable-padding {
    .ant-btn:active,
    .ant-btn:focus,
    .ant-btn:hover {
        background: #38C597;
        color: #fff;
    }
    h4 {
        font-size: 14px;
    }
    .note-details {
        color: #8B8B8B;
        font-weight: 300;
    }
}

@media only screen and (min-width:528px) {
    .expandable-padding {
        padding: 1.45rem 2.5rem 2.5rem;
    }
}

@media only screen and (max-width:527px) {
    .expandable-padding {
        padding: 0.45rem 0rem 0.5rem;
    }
}
