.center-row{justify-content: center;}
.clear
{
  clear: both;
}
.d-flex .ant-form-item-control-input-content{display: flex !important;}
.scrollContent {

    overflow: hidden;
        overflow-y: hidden;
    overflow-y: auto;
    width: 100%;
    float: left;
    clear: both;
    max-height: calc(100% - 100px);
    padding-bottom: 0;

}
.logo-text-section h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #2d3b55;
  margin-bottom: 30px;
}
.mb-20{
  margin-bottom: 20px;
}
.bank-logo{text-align: center;}
.t-center{text-align: center;}
.maskInput{position: relative;}
.show_hide {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.create-account-div .defaultForm .ant-input-affix-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 6px !important;

}
.oneline_name .ant-col {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.oneline_name .ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.oneline_name{float: left; width: 33.3%; padding-left: 0 !important; padding-right: 0 !important; margin-left: 0px !important;
margin-right: 0px !important;}
.oneline_name.pr-0 .ant-col{padding-right: 0 !important;}
.oneline_name.pl-0 .ant-col{padding-left: 0 !important;}
.oneline_address{float: left; width: 33.3%; padding-left: 0 !important; padding-right: 0 !important; margin-left: 0px !important;
margin-right: 0px !important;}
.oneline_address.pr-0 .ant-col{padding-right: 0 !important;}
.oneline_address.pl-0 .ant-col{padding-left: 0 !important;}
.oneline_address .ant-col {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.create-account-div{width: 520px;}
.ml-0 {
  margin-left: 0;
}
.oneline_address .ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
#dropdown-date{display: flex;}
#dropdown-date #dropdown-month{width: 33.33% !important;}
#dropdown-date #dropdown-day{width: 33.33% !important;}
#dropdown-date #dropdown-year{width: 33.33% !important;}
.review-month{width: 33.33% !important;}
.review-date{width: 33.33% !important;}
.review-year{width: 33.33% !important;}
.mr-10{margin-right: 10px;}
.ml-10{margin-left: 10px;}
.mx-10{margin-left: 10px; margin-right: 10px;}
.w-90{width: 90%;}
.city{width: 35%;}
.zipcode{width: 20%;}
.state{width: 45%;}
.action-btns{width: 100%;margin-left: 0 !important;
margin-right: 0 !important; margin-top: 20px;}
.action-btns .ant-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.action-btns .ant-form-item-control-input-content{
  display: flex;
  justify-content: space-between;
}
.ssn-input .ant-form-item-control-input-content{
  display: flex;
  align-items: center;
}
.ssn-input input
{
  border: 0 !important; background: none !important;padding: 0 !important;
}
.ssn-input .ant-form-item-control-input-content, #dropdown-date select {
  background: #FFFFFF;
  border: 1px solid #CECECE;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding: 6px 12px;
  width: 100%;
  height: 40px;
}
.ssn-input .area-number{width: 29px;}
.ssn-input .group-number{width: 19px;}
.ssn-input .serial-number{width: 48px;}
.ssn-input .delimiter {
  color: #959593;
  margin: 0 4px;
}
.submit-btn .ant-form-item-control-input-content{justify-content: flex-end;
display: flex}
.oneline_dob input{width: 50%;}
.action-btns .ant-row{margin-left: 0 !important;
margin-right: 0 !important;}
.feedback{
  color: #ff4d4f;
}
.defaultForm .review-field > label {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #2D3B55;
}
.review-field{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}
.bank-screen .spinnerCont .ant-spin {font-size: 24px !important;}
.required .ant-form-item-label::after {
  content: "*";
  color: red;
  float: right;
  position: absolute;
  top: 5px;
  left: 110px;
}
.defaultForm .ant-input[disabled], .defaultForm input[disabled], .defaultForm .react-tel-input .form-control[disabled], .disabled.ssn-input .ant-form-item-control-input-content, .disabled #dropdown-date select {
    color: rgba(0,0,0,.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}
.create-account-div .defaultForm .ant-input-affix-wrapper-has-feedback input{border: 0 !important;}
.disabled #dropdown-date select
{
  pointer-events: none;
  cursor: not-allowed;
}
.confirm-ssn-input.ant-form-item-has-error .ant-row .ant-form-item-control > div:nth-child(2)
{display:none;}
.ssn-input.confirm-ssn-input{margin-bottom: 0;}
.confirmssn-error{margin-bottom: 18px;}
.state .ant-select-selection-search-input{background: unset !important;}